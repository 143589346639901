<template>
    <div class="page-team"  >
        <div class="team-wrapper" :class="isScrolled?'active':'unactive'">
        <van-nav-bar
            :border="false" :placeholder="true" :fixed="true"
            :left-text="$t('我的团队')"
            left-arrow
            @click-left="onClickLeft"
            @click-right="onClickRight"
        >
            <!-- <template #right>
                <span style="width: 100px;color: #FFFFFF">{{$t('推荐规则')}}</span>
            </template> -->
        </van-nav-bar>
        <div class="page-main" :class="'skin'+ cur">
            <div class="uc-header flex-center u-m-b20">
                <div class="uc-grid flex-center">
                    <div class="uc-avatar">
                        <img v-if="user.headphoto" :src="user.headphoto">
                        <img v-else src="../../assets/pictures/default.png">
                    </div>
                    <div class="flex_bd">
                        <div class="uc-name text-primary" v-if="user && user.username" >
                            {{user.username}}
                        </div>
                    </div>
                    <div class="current-level">{{$t('当前经验值')}}：{{user.exp}}</div>
                </div>
            </div>
            <div class="team-level">
                <div class="team-vip-panel">
                    <img src="../../assets/pictures/V1.png" v-if="cur == 0" class="vip-ico" alt="">
                    <img src="../../assets/pictures/V2.png" v-if="cur == 1" class="vip-ico" alt="">
                    <img src="../../assets/pictures/V3.png" v-if="cur == 2" class="vip-ico" alt="">
                    <img src="../../assets/pictures/V4.png" v-if="cur == 3" class="vip-ico" alt="">
                    <img src="../../assets/pictures/V5.png" v-if="cur == 4" class="vip-ico" alt="">
                    <img src="../../assets/pictures/V6.png" v-if="cur == 5" class="vip-ico" alt="">
                    <div class="level-label" :class="'color-'+ cur">
                        {{$t('您已达成该等级')}}
                    </div>
                </div>
                <ul class="lv-tabs">
                    <li v-for="(item,index) in agent_list" :key="index" :class="cur + 1 > index ?'active':''">
                        <!-- @click="toslide(item.level - 1)" -->
                        <div class="lv-tab-item">
                            <div class="item">{{item.level}}</div>
                            <div class="dot"></div>
                            <div class="item">{{parseInt(item.exp)}}</div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="team-one">
            <div class="t-header u-m-b10">
                <span class="tt">{{$t('会员资料')}}</span>
                <router-link to="/team-member" class="t-more"><span>{{$t('更多')}}</span><van-icon name="arrow" /></router-link>
            </div>
            <div class="bs-panel">
                <div class="vip-cell flex-center" v-for="(item,index) in childs()" :key="index">
                    <img v-if="index == 0" src="../../assets/pictures/level-icon-1.png" class="v-ico" alt="">
                    <img v-if="index == 1" src="../../assets/pictures/level-icon-2.png" class="v-ico" alt="">
                    <img v-if="index == 2" src="../../assets/pictures/level-icon-3.png" class="v-ico" alt="">
                    <div class="flex_bd">{{ $t(item.name) }}</div>
                    <div class="value">{{ item.total }} {{ $t("人") }}</div>
                </div>
            </div>
        </div>
        <div class="team-two">
            <div class="t-header u-m-b10">
                <span class="tt">{{ $t("团队奖励") }}</span>
                <router-link to="/bonus" class="t-more"><span>{{$t('更多')}}</span><van-icon name="arrow" /></router-link>
            </div>
            <div class="bs-panel">
                <div class="rew-card">
                    <div class="rew-data">
                        <div class="col">
                            <div class="text">{{ $t("今天") }}</div>
                            <div class="val">{{ parseFloat(stat.today).toFixed(6) }}</div>
                        </div>
                        <div class="col">
                            <div class="text">{{ $t("本周") }}</div>
                            <div class="val">{{ parseFloat(stat.week).toFixed(6) }}</div>
                        </div>
                        <div class="col">
                            <div class="text">{{ $t("全部") }}</div>
                            <div class="val">{{ parseFloat(stat.total).toFixed(6) }}</div>
                        </div>
                    </div>
                </div>
                <div class="rew-cells">
                    <div class="rew-cell flex-center" v-for="(item, index) in list" :key="index">
                        <div class="rew-icon"><img src="../../assets/pictures/filedefault.png" ></div>
                        <div class="flex_bd">
                            <div class="rew-title">{{ item.title }}</div>
                            <div class="rew-date">{{ item.created_at }}</div>
                        </div>
                        <div class="rew-right">
                            <div class="rew-val u-m-b5" v-if="item.money > 0">+{{ parseFloat(item.money).toFixed(6) }}</div>
                        <div class="rew-val" v-else>{{ parseFloat(item.money).toFixed(4) }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <Foot :active="2"/>
    </div>
</template>

<script>
    import Foot from "@/components/Foot.vue";

    export default {
        components: {
            Foot
        },
        data() {
            return {
                show: false,
                name: "",
                color: ["#a77f41", "#dc3336", "#0b265c", "#065029", "#241384"],
                user: false,
                isScrolled:false,
                cur:0,
                list: [],
                agent_list: [],
                child_list: [],
                agent: {},
                exp_progress: 0,
                count: [0, 0, 0],
                stat: {
                    today: 0,
                    week: 0,
                    total: 0,
                },
                team: {
                    level: "T1",
                    pass: 0,
                    money: 0.0,
                },
                swiperOption: {
                    observer: true,
                    observeParents: true,
                    slidesPerView: 'auto',
                    spaceBetween: 15,
                    centeredSlidesBounds: true,
                },
            };
        },
        methods: {
            toslide(index){
                this.cur = index;
            },
            initSwipe(){
                let index = 0;
                if(this.user.agent){
                    index = this.user.agent.level - 2;
                }
                if(index >= 5){
                    index = 5;
                }
                return index;
            },
            onClickRight() {
                this.$router.push({path: '/tm-rule'})
            },
            getExpRate(u, a) {
                if (u > a) {
                    return 100;
                }
                return parseFloat(u / a * 100).toFixed(2);
            },
            childs() {
                // let level = this.user && this.user.agent && typeof this.user.agent.level !== 'undefined' ? this.user.agent.level : 0;
                // return this.child_list.filter(function (item) {
                //     return item.level <= level;
                // });
                return this.child_list;
            },
            viewAgent(item) {
                this.agent = item
                this.exp_progress = (this.user.exp / this.agent.exp * 100).toFixed(0);
                if (this.exp_progress > 100) {
                    this.exp_progress = 100;
                }

                if (this.exp_progress < 0) {
                    this.exp_progress = 0;
                }

            },
            agents() {
                return this.agent_list.filter(function (item) {
                    return item.level > 1;
                });
            },
            onClickLeft() {
                this.$router.go(-1);
            },
            getData() {
                let url = this.$api_url.distributions;
                this.$axios
                    .get(url)
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.count = res.data.data.count;
                            this.list = res.data.data.list;
                            this.agent_list = res.data.data.agent_list;
                            this.child_list = res.data.data.child_list;
                            if (this.agent_list.length > 1) {
                                this.viewAgent(this.agent_list[1])
                            }
                            this.stat = res.data.data.stat;
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(err.message);
                    });
            },
            handleScroll() {
                if (window.pageYOffset >= 100) {
                    this.isScrolled = true
                } else {
                    this.isScrolled = false
                }
            }
        },
        mounted() {
            this.getData();
            this.getUser().then((res) => {
                this.user = res.data;
                let cc =  this.user.agent.level - 1;
                if(cc < 0){
                    cc = 0;
                }
                if(cc > 5){
                    cc = 5;
                }
                this.cur = cc;
                console.log(this.user);
            });
            window.addEventListener('scroll', this.handleScroll)
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleScroll)
        },
    };
</script>
<style scoped>
    .page-main{
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 22;
        padding-top: 60px;
        margin-top: -60px;
        border-radius: 0 0 30px 30px;
    }
    .team-one{
        background-color: #1a2331;
        border-radius: 0 0 30px 30px;
        margin-top: -20px;
        padding: 15px;
        padding-top: 40px;
    }
    .team-two{
        padding: 15px;
    }
    .rew-cells{
        padding: 10px 20px;
        border-top: 1px solid #313132;
    }
    .rew-cell{
        padding: 10px 0;
        font-size: 14px;
    }
    .rew-date{
        opacity: .5;
    }
    .rew-icon {
        font-size: 0;
    }
    .rew-icon img{
        width: 30px;
        height: 30px;
        border-radius: 30px;
    }


    .page-team{
        overflow: hidden;
    }
    .bs-panel_bd{
        padding:10px 15px;
    }
    .vip-cell{
        padding: 10px 0;
    }
    .vip-cell .v-ico{
        margin-right: 10px;
        width:23px;
    }


    .t-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .t-header .tt{
        font-size:16px;
        font-weight: bold;
        color: #ffffff;
    }
    .t-header .t-more{
        opacity: .6;
    }
    .rew-data{
        display: flex;
        padding:20px 15px;
    }
    .rew-data .col{
        flex: 1;
    }
    .rew-data .text{
        margin-bottom: 10px;
        opacity: .7;
    }
    .rew-data .val{
        font-weight: bold;
        font-size: 16px;
    }
    .lv-tabs{
        position: relative;
        display: flex;
        font-size: 14px;
        text-align: center;
    }
    .lv-tabs::before{
        content: "";
        position: absolute;
        left: -20px;
        right: -20px;
        display: block;
        height: 1px;
        transform: translateY(-50%);
        background-color: #3653ea;
        top: 50%;
    }
    .lv-tabs li{
        position: relative;
        z-index: 2;
        list-style: none;
        flex: 1;
        width: 100%;
        text-align: center;
        padding: 10px 0;
        transition: all.3s;
    }
    .lv-tabs li::after{
        content: "";
        position: absolute;
        left: 0px;
        right: 0px;
        display: block;
        height: 3px;
        transform: translateY(-50%);
        background-color: #3653ea;
        top: 50%;
    }
    .lv-tabs li.active::after{
        background-color: #4b69ff;
    }


    .lv-tab-item{
        color: #3653ea;
    }
    .lv-tab-item .dot{
        margin: 10px auto;
        width: 8px;
        height: 8px;
        background-color: #3653ea;
        border-radius: 20px;
    }
    .active .dot{
        background-color: #4b69ff;
    }
    .page-team{
        background-repeat: no-repeat;
        background-position: center top;
        background-size: contain;
    }
    .skin0{
        background-image: url(../../assets/pictures/level-1.png);
    }
    .skin1{
        background-image: url(../../assets/pictures/level-2.png);
    }
    .skin2{
        background-image: url(../../assets/pictures/level-3.png);
    }
    .skin3{
        background-image: url(../../assets/pictures/level-4.png);
    }
    .skin4{
        background-image: url(../../assets/pictures/level-5.png);
    }
    .skin5{
        background-image: url(../../assets/pictures/level-6.png);
    }
    .current-level{
        font-size: 12px;
        display: inline-flex;
        align-items: center;
        margin-left: 10px;
        padding: 0 6px;
        border: 1px solid #3270e0;
        background-color: #3270e0;
        font-size: 12px;
        color: #ffffff;
        height: 24px;
        border-radius: 4px;
    }

    .team-wrapper.unactive ::v-deep .van-nav-bar{
        background-color: transparent!important;
    }
    .team-vip-panel{
        position: relative;
    }
    .level-label{
        position: absolute;
        z-index: 2;
        left: 20px;
        top: 50%;
        margin-top: 20px;
        font-size: 15px;
    }
    .color-0{
        color: #46709a;
    }
    .color-1{
        color: #916452;
    }
    .color-2{
        color: #9d6845;
    }
    .color-3{
        color: #3d2b8a;
    }
    .color-4{
        color: #c3cfe2;
    }
    .color-5{
        color: #ffe87e;
    }

</style>
